<template>
<div class="alarm-rule-container">
  <div class="query-data-area">
    <span class="ml10">{{showLang('com.tab.title')}}：</span>
    <Input type="text" v-model="filter.name" clearable :placeholder="showLang('com.device.pole.name')" style="width: 200px; margin-right: 10px;"></Input>
    <Button size="default" type="primary" @click="getList" style="margin-right: 10px;">{{showLang('com.op.query')}}</Button>
    <AuthButton opCode='pld' size="default" type="error" @click="deleteParams">批量删除</AuthButton>
  </div>
  <div class="alarm-data-area" ref="table1">
    <vxe-table ref="chkTable" border show-overflow show-header-overflow :data="list" :height="tabHeight" :row-config="{isHover: true}">
      <vxe-column width="50" type="checkbox" field="checkbox" fixed="left"></vxe-column>
      <vxe-column field="name" title="名称" fixed="left" width="150"></vxe-column>
      <vxe-column field="code" title="设备通信ID" fixed="left" width="100"></vxe-column>
      <vxe-column field="poleName" title="所在灯杆" width="150"></vxe-column>
      <vxe-column field="useTypeName" title="应用类型" width="120"></vxe-column>
      <vxe-column field="deviceTypeName" title="设备类型" width="200"></vxe-column>
      <vxe-column field="gatewayName" title="通信网关" width="150">
        <template #default="params">
          {{params.row.gatewayName ? params.row.gatewayName : '不使用'}}
        </template>
      </vxe-column>
      <vxe-column field="channel" title="上行通道" width="80">
        <template #default="params">
          {{params.row.gatewayName ? params.row.channel : '-'}}
        </template>
      </vxe-column>
      <vxe-column field="voltageAlarm" title="电压报警" width="80">
        <template #default="params">
          {{params.row.voltageAlarm ? '启用' : '禁用'}}
        </template>
      </vxe-column>
      <vxe-column field="voltageLower" title="下限值" width="80"></vxe-column>
      <vxe-column field="voltageUpper" title="上限值" width="80"></vxe-column>
      <vxe-column field="currentAlarm" title="电流报警" width="80">
        <template #default="params">
          {{params.row.currentAlarm ? '启用' : '禁用'}}
        </template>
      </vxe-column>
      <vxe-column field="currentLower" title="下限值" width="80"></vxe-column>
      <vxe-column field="currentUpper" title="上限值" width="80"></vxe-column>
      <vxe-column field="tempAlarm" title="温度报警" width="80">
        <template #default="params">
          {{params.row.tempAlarm ? '启用' : '禁用'}}
        </template>
      </vxe-column>
      <vxe-column field="tempLevel1" title="一级阈值" width="80"></vxe-column>
      <vxe-column field="tempLevel2" title="二级阈值" width="80"></vxe-column>
      <vxe-column field="leakageCurrentAlarm" title="漏电电流报警" width="110">
        <template #default="params">
          {{params.row.leakageCurrentAlarm ? '启用' : '禁用'}}
        </template>
      </vxe-column>
      <vxe-column field="leakageCurrentUpper" title="漏电电流阈值" width="110"></vxe-column>
      <vxe-column field="leakageVoltageAlarm" title="漏电电压报警" width="110">
        <template #default="params">
          {{params.row.leakageVoltageAlarm ? '启用' : '禁用'}}
        </template>
      </vxe-column>
      <vxe-column field="leakageVoltageUpper" title="漏电电压阈值" width="110"></vxe-column>
      <vxe-column field="enable1" title="一路控制" width="80">
        <template #default="params">
          {{params.row.enable1 ? '启用' : '禁用'}}
        </template>
      </vxe-column>
      <vxe-column field="after1" title="额定功率" width="80"></vxe-column>
      <vxe-column field="before1" title="改造前功率" width="100"></vxe-column>
      <vxe-column field="count1" title="光源数量" width="80"></vxe-column>
      <vxe-column field="enable1" title="二路控制" width="80">
        <template #default="params">
          {{params.row.enable2 ? '启用' : '禁用'}}
        </template>
      </vxe-column>
      <vxe-column field="after2" title="额定功率" width="80"></vxe-column>
      <vxe-column field="before2" title="改造前功率" width="100"></vxe-column>
      <vxe-column field="count2" title="光源数量" width="80"></vxe-column>
      <vxe-column field="buildDate" title="安装日期" width="120"></vxe-column>
      <vxe-column width="150" title="操作" fixed="right">
        <template #header>
          <Button v-if="funCodes('pla')" size="small" type="primary" @click="handleAdd" style="margin-right: 5px">新增</Button>
          <Button v-if="funCodes('plv')" size="small" type="primary" @click="getList" style="margin-right: 5px">刷新</Button>
        </template>
        <template #default="params">
          <Button v-if="funCodes('ple')" size="small" type="info" @click="handleEdit(params)" style="margin-right: 5px">编辑</Button>
          <Button v-if="funCodes('pld')" size="small" type="error" @click="handleDelete(params)" style="margin-right: 5px">删除</Button>
        </template>
      </vxe-column>
    </vxe-table>
    <vxe-pager :layouts="pageLayouts" :current-page.sync="filter.index" :page-size.sync="filter.size" :total="total" :page-sizes='pageSizes' @page-change="handlePageChange"></vxe-pager>
    <ModalItemEdit v-model="showEditModal" :item="editItem" :deviceTypes="deviceTypes" :useTypes="useTypes" :gateways="gateways" @saved="itemSaved" />
  </div>
</div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import ModalItemEdit from './ModalItemEdit'
export default {
  name: 'ConfigLedIndex',
  components:{
    ModalItemEdit,
  },
  props: {
  },
  data () {
    return {
      list: [],
      total: 0,
      tabHeight: 600,
      editItem: {isAdd: true, data: {}},
      showEditModal: false,
      filter: {
        groupId: 0,
        poleId: '',
        gatewayId: '',
        useType: 0,
        planId1: 0,
        planId2: 0,
        mode: -1,
        name: '',
        index: 1,
        size: 20
      },
      connectTypes: [],
      deviceTypes: [],
      useTypes: [],
      gateways: [],
    }
  },
  computed: {
    ...mapState('cmd', ['windowOnResize', 'cmds']),
    ...mapGetters('auth', ['funCodes', 'showLang']),
    ...mapState('common', ['weeks', 'pageSizes', 'pageLayouts']),
    ...mapState('group', ['selectedGroups', 'selectedDevices', 'poleSelectedNode']),
  },
  watch: {
    windowOnResize(){
      this.setTableHeight();
    },
    poleSelectedNode(){
      this.filter.index = 1;
      this.getList();
    }
  },
  mounted: function(){
    setTimeout(this.setTableHeight, 100);
    // this.getConnectTypes();
    this.getGateways();
    this.getUseTypes();
    this.getDeviceTypes();
    this.getList();
  },
  destroyed: function(){
  },
  methods: {
    handlePageChange({ currentPage, pageSize }) {
      this.filter.index = currentPage
      this.filter.size = pageSize
      this.getList(true)
    },
    getCheckedItems: function(){//查询所有被选中的记录
      return this.$refs.chkTable.getCheckboxRecords();
    },
    setTableHeight: function(){
      this.tabHeight = this.$refs.table1.clientHeight - 50;
    },
    handleAdd: function(){
      this.editItem = {isAdd: true, data: {}};
      this.showEditModal = true;
    },
    handleEdit: function(params){
      this.editItem = {isAdd: false, data: params.row};
      this.showEditModal = true;
    },
    itemSaved: function(){
      this.getList();
    },
    deleteParams: function(){
      let chks = this.$refs.chkTable.getCheckboxRecords();
      if(chks.length == 0){
        this.$Message.warning('请选择要删除的记录');
        return;
      }
      // console.log('get selected', chks)
      let list = chks.map(p => p.id);
      this.$Modal.confirm({
        title: "提示",
        content: `确定要删除已选的[${list.length}]条记录吗?`,
        onOk: async () => {
          this.$axios.post(`//${this.domains.trans}/pole/config/DeleteLampInfo`, {list: list}).then(res => {
            if(res.code != 0){
              // this.$Message.error(res.data);
            }else{
              this.getList();
            }
          });
        }
      });
    },
    handleDelete: function(params){
      this.$Modal.confirm({
        title: "提示",
        content: `确定要删除[${params.row.name}]吗?`,
        onOk: async () => {
          this.$axios.post(`//${this.domains.trans}/pole/config/DeleteLampInfo`, {list: [params.row.id]}).then(res => {
            if(res.code != 0){
              // this.$Message.error(res.data);
            }else{
              this.getList();
            }
          });
        }
      });
    },
    getList: function(){
      let groupId = '', poleId = '';
      if(this.poleSelectedNode.isLeaf){
        poleId = this.poleSelectedNode.id;
      }else{
        groupId = this.poleSelectedNode.id;
      }
      this.filter.groupId = groupId;
      this.filter.poleId = poleId;
      this.$axios.post(`//${this.domains.trans}/pole/config/QueryLampList`, this.filter).then(res => {
        if(res.code != 0){
          // this.$Message.error(res.data);
        }else{
          this.total = res.data.count;
          this.$set(this, 'list', res.data.list);
        }
      });
    },
    // getConnectTypes: function(){
    //   this.$axios.post(`//${this.domains.trans}/pole/config/GetBroadConnectionTypes`, {}).then(res => {
    //     if(res.code != 0){
    //       // this.$Message.error(res.data);
    //     }else{
    //       this.$set(this, 'connectTypes', res.data);
    //     }
    //   });
    // },
    getDeviceTypes: function(){
      this.$axios.post(`//${this.domains.trans}/pole/config/GetLampDeviceTypes`, {}).then(res => {
        if(res.code != 0){
          // this.$Message.error(res.data);
        }else{
          this.$set(this, 'deviceTypes', res.data);
        }
      });
    },
    getUseTypes: function(){
      this.$axios.post(`//${this.domains.trans}/pole/config/GetLampUseTypes`, {}).then(res => {
        if(res.code != 0){
          // this.$Message.error(res.data);
        }else{
          this.$set(this, 'useTypes', res.data);
        }
      });
    },
    getGateways: function(){
      this.$axios.post(`//${this.domains.trans}/pole/config/QueryGatewayList`, {size: 100000}).then(res => {
        if(res.code != 0){
          // this.$Message.error(res.data);
        }else{
          this.$set(this, 'gateways', res.data.list);
        }
      });
    },
  }
}
</script>
<style scoped>
.alarm-rule-container{
  height: 100%;
  display: flex;
  flex-direction: column;
  align-content: stretch;
  /* padding: 10px; */
  /* padding-right: 40px; */
  /* border: solid 1px red; */
}
.query-data-area{
  height: 45px;
  flex: none;
  display: flex;
  align-items: center;
  border: solid 1px rgba(143, 138, 138, 0.452);
  margin-bottom: 5px;
}
.alarm-data-area{
  /* border: solid 1px red; */
  height: 100px;
  flex: auto;
  /* border: solid 1px #DCDEE2; */
  border-radius: 6px;
  /* padding-left: 10px; */
}
.station-alarm-tabs{
  height: 30px;
  flex: none;
  display: flex;
}
.station-alarm-tab{
  padding: 3px 8px;
  margin: 3px 3px 0 3px;
  border: solid 1px lightgray;
  border-radius: 5px 5px 0 0;
  cursor: pointer;
  background-color: white;
}
.tab-active{
  border: solid 1px rgb(135, 159, 224);
  background-color: rgb(135, 159, 224);
  color: white;
}
.station-alarm-tabcontent{
  height: 500px;
  flex: auto;
  /* border: solid 1px red; */
}
</style>