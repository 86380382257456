<template>
<div class="device-item-content">
  <div class="device-item-header">
    <img class="device-item-header-icon" :src="img.pole.broad.base.icon" title="广播" />
    <span class="device-item-header-title">{{item.name}}</span>
    <img class="device-item-header-status-icon" :src="online ? img.pole.online : img.pole.offline" />
    <span class="device-item-header-status">{{online ? '在线' : '离线'}}</span>
  </div>
  <div class="form-item">
    <span>播放音量</span> <Slider v-model="form.vol" @on-change="boardVolChange"></Slider>
  </div>
  <div class="form-item">
    <span>播放计划</span>
    <Select v-model="form.programId" style="width:200px">
      <Option v-for="item in tasks" :value="item.id" :key="item.id">{{ item.name }}</Option>
    </Select>
  </div>
  
  <div @click="setPlan" class="float-btn" style="right:265px;">设定计划</div>
  <div @click="switchMedia" class="float-btn" style="right:185px;">手动播放</div>
  <div @click="playBoard" class="float-btn" style="right:105px;">开始播放</div>
  <div @click="stopBoard" class="float-btn" style="right:25px;">停止播放</div>
  
  <ModalSelectMedia v-model="showDownloadModal" @selected="mediaSelected" :onlyMp3="true" />
</div>
</template>
<script>
import { mapState } from 'vuex'
import ModalSelectMedia from './ModalSelectMedia'
export default {
  name: 'BroadStationViewer',
  components:{
    ModalSelectMedia,
  },
  props: {
    item: {
      type: Object,
      default(){return {}},
    },
    tasks: {
      type: Array,
      default(){return []},
    },
  },
  data () {
    return {
      form: {
        vol: 50,
        programId: '',
        oldProgramId: '',
      },
      broadTasks: [],
      wills: [],
      groups: [],
      treeData: [],
      medias: [],
      showDownloadModal: false,
      selectedGroup: {},
      previewMedia: {type: 0},
    }
  },
  computed: {
    ...mapState('common', ['productCodes', 'powerBoxTypes', 'mediaTypes']),
    online: function(){
      if(!this.item)return false;
      return this.item.online;
    },
  },
  watch: {
    item(){
      this.initData();
    },
  },
  mounted: function(){
    // console.log('broad info show', this.item)
    window.eventBus.$on('commandReplyComing', params => {
      if(this.cmd._cmdId != params.cmdId)return;
      this.$set(this.cmd, '_cmd', params.code);
      this.$set(this.cmd, '_message', params.message);
      this.$set(this.cmd, '_content', params.content);
      this.$set(this.cmd, '_status', params.status);
      if (params.status == 0) {
        this.showSpin(`指令已进入队列`);
      } else if (params.status == 2){
        this.showSpin('等待设备应答');
      } else if(params.status == 7){
        this.showSpin(`指令超时：${this.cmd._message}`);
        setTimeout(this.hideSpin, 2000);
      } else if(params.status == 8) {
        this.showSpin(`执行失败：${this.cmd._message}`);
        setTimeout(this.hideSpin, 2000);
      } else if(params.status == 9){
        this.showSpin(`执行成功`);
        setTimeout(this.hideSpin, 1000);
        switch(this.cmd._cmd){
          case 4212:
          case 4213:
            this.$set(this.item, 'bright', this.cmd._content.bright);
            break;
          case 4214:
            this.$set(this.item, 'volume', this.cmd._content.volume);
            break;
          case 4215:
            this.$set(this.item, 'img', this.cmd._content.img);
            break;
          case 4217:
            this.$set(this.item, 'mediaId', this.cmd._content.mediaId);
            this.$set(this.item, 'mediaName', this.cmd._content.mediaName);
            this.$set(this.item, 'isTask', this.cmd._content.isTask);
            this.$set(this.item, 'running', this.cmd._content.running);
            break;
        }
      }
    })
    this.initData();
  },
  destroyed: function () {
    window.eventBus.$off('commandReplyComing');
  },
  methods: {
    sendCommand: function(ids, code, args){
      this.$set(this.cmd, '_cmd', code);
      this.$set(this.cmd, '_cmdId', '');
      this.$set(this.cmd, '_message', "指令已创建");
      this.$set(this.cmd, '_content', null);
      this.$set(this.cmd, '_status', 0);
      setTimeout(() => {
        this.$axios.post(`//${this.domains.trans}/pole/command/SendBroad`, {code, list: ids, args}).then(res => {
          if(res.code != 0){
            // this.$Message.error(res.data);
          }else{
            // console.log('send led cmd result', res.data, this.list)
            for(let cmd of res.data.list){
              if(cmd.id != this.item.id)continue;
              let message = '指令已发送', status = 2, cmdId = cmd.message;
              if(!cmd.success){
                message = cmd.message;
                status = 8;
                cmdId = '';
                this.showSpin(`执行失败：${message}`);
                setTimeout(this.hideSpin, 2000);
              }
              this.$set(this.cmd, '_cmd', code);
              this.$set(this.cmd, '_cmdId', cmdId);
              this.$set(this.cmd, '_message', message);
              this.$set(this.cmd, '_content', null);
              this.$set(this.cmd, '_status', status);
            }
          }
        });
      }, 300);
    },
    setPlan: function(){
      this.sendCommand([this.item.id], 4313, {mediaId: this.form.programId});
      // if(this.form.programIds.length == 0){
      //   this.$Message.warning('请选择要设置的播放计划');
      //   return;
      // }
      // this.$axios.post(`//${this.domains.trans}/pole/broad/SetTask`, {ids: [this.item.id], taskIds: this.form.programIds}).then(res => {
      //   if(res.code != 0){
      //     // this.$Message.error(res.data);
      //     return;
      //   }
      //   this.$Message.info('已设置播放计划');
      // })
    },
    mediaSelected: function(params){
      this.sendCommand([this.item.id], 4312, {mediaId: params.id});
      // this.$axios.post(`//${this.domains.trans}/pole/broad/PlayMedia`, {ids: [this.item.id], mediaId: params.id}).then(res => {
      //   if(res.code != 0){
      //     // this.$Message.error(res.data);
      //     return;
      //   }
      //   this.$Message.info('节目已下发');
      // })
    },
    initData: function(){
      this.form.programId = this.item.mediaId || 0;
      if(this.item){
        this.form.vol = this.item.volume || 50;
      }else{
        this.form.vol = 50;
      }
    },
    switchMedia: function(){
      this.showDownloadModal = true;
    },
    boardVolChange: function(){
      this.sendCommand([this.item.id], 4311, {volume: this.form.vol});
      // this.$axios.post(`//${this.domains.trans}/pole/broad/setVolume`, {ids:[this.item.id], vol: this.form.vol}).then(res => {
      //   if(res.code != 0){
      //     // this.$Message.error(res.data);
      //     return;
      //   }
      //   this.$Message.info(`音量已调节至 ${this.form.vol}`);
      // });
    },
    playBoard: async function(){
      this.sendCommand([this.item.id], 4312, {});
      // this.$axios.post(`//${this.domains.trans}/pole/broad/ControlPlay`, {start: true, ids: [this.item.id]}).then(res => {
      //   if(res.code != 0){
      //     // this.$Message.error(res.data);
      //     return;
      //   }
      //   this.$Message.info(`已开始播放`);
      // });
    },
    stopBoard: function(){
      this.sendCommand([this.item.id], 4310, {});
      // this.$axios.post(`//${this.domains.trans}/pole/broad/ControlPlay`, {start: false, ids: [this.item.id]}).then(res => {
      //   if(res.code != 0){
      //     // this.$Message.error(res.data);
      //     return;
      //   }
      //   this.$Message.info(`已停止播放`);
      // });
    },
  }
}
</script>
<style scoped>
.float-btn{
  position:absolute;
  /* right:25px; */
  bottom:20px;
  width:74px;
  height:32px;
  line-height:32px;
  text-align:center;
  cursor:pointer;
  font-size: 14px;
  background: #1365BC;
  border-radius: 4px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #FFFFFF;
}
.form-item{
  /* border: solid 1px red; */
  /* margin: 5px 0; */
  display: flex;
  color: white;
  padding-right: 30px;
}
.form-item div{
  flex: auto;
  margin-left: 10px;
  /* margin-bottom: 3px;
  display: flex;
  color: white; */
}
.form-item span{
  width: 80px;
  flex: none;
  text-align: right;
  line-height: 35px;
  margin-left: 10px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #68737D;
  /* margin-bottom: 3px;
  display: flex;
  color: white; */
}
.device-item-content{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;
}
.device-item-header{
  height: 48px;
  /* background: #E8F1FA; */
  border-radius: 8px 8px 0px 0px;
  display: flex;
  align-items: center;
}
.device-item-header-icon{
  width: 20px;
  height: 20px;
  margin-left: 25px;
  margin-right: 10px;
  flex: none;
}
.device-item-header-title{
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #021629;
  width: 10px;
  flex: auto;
}
.device-item-header-status-icon{
  width: 14px;
  height: 14px;
  margin-right: 7px;
}
.device-item-header-status{
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #324252;
  flex: none;
  margin-right: 25px;
}
</style>
