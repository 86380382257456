<template>
  <div class="device-item-content">
    <div class="device-info" v-for="(item,index) in devices" :key="index">
      <div class="device-info-img" ref="infoHight">
        <img :src="getImage(item)" alt="">
      </div>
      <div class="device-info-box">
        <div class="device-info-upper">
          <div class="device-info-tab">
            <div class="device-info-icon">
              <img :src="img.pole.icons.broad.deviceName" alt="">
            </div>
            <div class="device-info-con">
              <div class="device-info-val">{{item.name}}</div>
              <div class="device-info-title">当前播放</div>
            </div>
          </div>
          <div class="device-info-tab">
            <div class="device-info-icon">
              <img :src="img.pole.asset.index.name" alt="">
            </div>
            <div class="device-info-con">
              <div class="device-info-val">{{item.name}}</div>
              <div class="device-info-title">设备名称</div>
            </div>
          </div>
          <div class="device-info-tab">
            <div class="device-info-icon"><img :src="img.pole.asset.index.pole" alt=""></div>
            <div class="device-info-con">
              <div class="device-info-val"> {{item.stationName}}</div>
              <div class="device-info-title">所属灯杆</div>
            </div>
          </div>
          <div class="device-info-tab">
            <div class="device-info-icon"><img :src="img.pole.asset.index.type" alt=""></div>
            <div class="device-info-con">
              <div class="device-info-val">{{item.connectTypeName}}</div>
              <div class="device-info-title">设备类型</div>
            </div>
          </div>
          <div class="device-info-tab">
            <div class="device-info-icon"><img :src="img.pole.asset.index.date" alt=""></div>
            <div class="device-info-con">
              <div class="device-info-val">{{item.buildDate}}</div>
              <div class="device-info-title">安装日期</div>
            </div>
          </div>
        </div>
        <div class="device-info-lower">
          <div class="device-info-operation">
            <span>播放计划</span>
            <Select v-model="item.content.tasks" style="width:200px" multiple>
              <Option v-for="item in mediaTasks" :value="item.id" :key="item.id">{{ item.name }}</Option>
            </Select>
          </div>
          <div class="device-info-operation">
            <span>播放音量</span>
            <Slider v-model="item.lastData.volume" @on-change="boardVolChange(item)" show-input style="width:250px"></Slider>
          </div>
          <div class="device-info-operation">
            <a href="javascript:void(0);" class="cameraInfo-tab-but" @click="setPlan(item)"><img :src="img.pole.icons.broad.Plan" alt="">设定计划</a>
            <a href="javascript:void(0);" class="cameraInfo-tab-but" @click="switchMedia(item)"><img :src="img.pole.icons.broad.Manual" alt="">手动播放</a>
            <a href="javascript:void(0);" class="cameraInfo-tab-but" @click="playBoard(item.id)"><img :src="img.pole.icons.broad.Start" alt="">开始播放</a>
            <a href="javascript:void(0);" class="cameraInfo-tab-but" @click="stopBoard(item.id)"><img :src="img.pole.icons.broad.cease" alt="">停止播放</a>
            <!-- <a href="javascript:void(0);" class="cameraInfo-tab-but" @click="testBut(item.id)"><img :src="img.pole.icons.broad.TaskPlan" alt="">计划播放</a>
            <a href="javascript:void(0);" class="cameraInfo-tab-but" @click="testBut(item.id)"><img :src="img.pole.icons.broad.run" alt="">播放管理</a> -->
          </div>

        </div>
        <ModalSelectMedia v-model="showDownloadModal" @selected="mediaSelected" :onlyMp3="true" />
      </div>
    </div>
    <div class="device-chart" style="height:560px">
      <recordPage :devId="devId" :devices="devices" />
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import recordPage from '../history/Index'
import ModalSelectMedia from '@/views/pole/monitor/main/base/ModalSelectMedia'
export default {
  name: 'deviceList',
  components: {
    recordPage,
    ModalSelectMedia
  },
  props: {
    devices: {
      type: Array,
      default(){return []},
    },
    oneId: {
      type: String,
      default: "",
    }
  },
  data() {
    return {
      form: {
        vol: 50,
        programIds: '',
        oldProgramId: '',
      },
      tasks: [],
      timer: 0,
      filter: {
        start: "",
        end: "",
      },
      devId: -1,
      selectDevID: 0,
      mediaTasks: [],
      showDownloadModal: false,
      chartHeight: 600,
    }
  },
  computed: {
    ...mapState('group', ['selectedNode', 'selectedDevices']),
    ...mapGetters('group', ['groupPathName']),
    ...mapState('common', ['productCodes', 'powerBoxTypes', 'mapCenter']),
  },
  watch: {
    selectedNode() {
    },
  },
  mounted: function () {
    this.getAllMediaTasks();
  },
  methods: {
    getAllMediaTasks: function () {
      this.$axios.get(`//${this.domains.trans}/media/task`).then(res => {
        if (res.code != 0) {
          // this.$Message.error(res.data);
          return;
        }
        this.$set(this, 'mediaTasks', res.data);
      })
    },
    setPlan: function (form) {
      if (form.content.tasks.length == 0) {
        this.$Message.warning('请选择要设置的播放计划');
        return;
      }
      this.$axios.post(`//${this.domains.trans}/pole/broad/SetTask`, { ids: [form.id], taskIds: form.content.tasks }).then(res => {
        if (res.code != 0) {
          // this.$Message.error(res.data);
          return;
        }
        this.$Message.info('已设置播放计划');
      })
    },
    switchMedia: function (form) {
      this.selectDevID = form.id;
      this.showDownloadModal = true;
    },
    mediaSelected: function (params) {
      this.$axios.post(`//${this.domains.trans}/pole/broad/PlayMedia`, { ids: [this.selectDevID], mediaId: params.id }).then(res => {
        if (res.code != 0) {
          // this.$Message.error(res.data);
          return;
        }
        this.$Message.info('节目已下发');
      })
    },
    playBoard: async function (id) {
      this.$axios.post(`//${this.domains.trans}/pole/broad/ControlPlay`, { start: true, ids: [id] }).then(res => {
        if (res.code != 0) {
          // this.$Message.error(res.data);
          return;
        }
        this.$Message.info(`已开始播放`);
      });
    },
    stopBoard: function (id) {
      this.$axios.post(`//${this.domains.trans}/pole/broad/ControlPlay`, { start: false, ids: [id] }).then(res => {
        if (res.code != 0) {
          // this.$Message.error(res.data);
          return;
        }
        this.$Message.info(`已停止播放`);
      });
    },
    boardVolChange(form) {
      this.$axios.post(`//${this.domains.trans}/pole/broad/setVolume`, { ids: [form.id], vol: form.lastData.volume }).then(res => {
        if (res.code != 0) {
          // this.$Message.error(res.data);
          return;
        }
        this.$Message.info(`音量已调节至 ${this.form.vol}`);
      });
    },
    getStationName: function (id) {
      let ss = this.selectedDevices.filter(p => p.id == id);
      if (ss.length == 0) return '-';
      return ss[0].name;
    },
    boxRoad(stationId) {
      let ss = this.selectedDevices.filter(p => p.id == stationId);
      if (ss.length == 0) return '--';
      return `${this.groupPathName(ss[0].groupId)}`;
    },
    getImage: function (item) {
      if (!item.online) return this.img.pole.icons.broad.offline3;
      return this.img.pole.icons.broad.online3;
    },
  }
}
</script>
<style scoped>
.cameraInfo-search-but {
  padding: 3px 12px;
  display: inline-block;
  background: #007eff;
  border-radius: 4px;
  vertical-align: middle;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
  line-height: 28px;
  margin-left: 19px;
}
.cameraInfo-search-but img {
  display: inline-block;
  width: 14px;
  height: 14px;
  vertical-align: middle;
  margin-right: 3px;
}
.device-item-content {
  width: 100%;
}
.device-info {
  display: flex;
  justify-content: flex-start;
  background-color: #fff;
  border-radius: 10px;
  padding: 20px 20px;
  margin-bottom: 14px;
}
.device-info-img {
  text-align: center;
  margin-right: 30px;
  margin-top: 15px;
}
.device-info-img img {
  margin-top: 2px;
}
.device-info-tab {
  display: flex;
  justify-content: flex-start;
}
.device-info-operation {
  display: flex;
  margin-right: 40px;
}
.device-info-operation span {
  width: 60px;
  flex: none;
  text-align: right;
  line-height: 35px;
  margin-right: 10px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #506179;
}
.device-chart {
  width: calc(100%);
  background: #fff;
  border-radius: 10px;
}
.device-info-icon img {
  margin-top: 5px;
  margin-right: 14px;
}
.device-info-con {
  width: 140px;
}
.device-info-val {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #24334a;
}
.device-info-title {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #8793a5;
}
.device-info-upper {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 25px;
}
.device-info-lower {
  display: flex;
  justify-content: flex-start;
}
.device-info-but img {
  vertical-align: middle;
}
.cameraInfo-tab-but img {
  vertical-align: middle;
  margin-right: 3px;
}
.cameraInfo-tab-but {
  height: 35px;
  padding: 2px 12px;
  display: inline-block;
  background: #007eff;
  border-radius: 4px;
  vertical-align: middle;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
  line-height: 30px;
  margin: 0 5px;
}
.device-info-operation /deep/ .ivu-slider-input .ivu-input-number {
  margin-top: 0;
  margin-top: 0;
  width: 45px;
  left: -40px;
}
</style>
