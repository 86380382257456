var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('bm-marker',{attrs:{"dragging":false,"icon":{ url: _vm.getImgType(_vm.item), size: { width: 26, height: 36 } },"position":{ lng: _vm.item.lng, lat: _vm.item.lat },"name":_vm.item.name},on:{"click":_vm.showMakerInfo}},[_c('bm-label',{attrs:{"content":_vm.item.name,"offset":{ width: -60, height: 30 },"labelStyle":{
      width: '150px',
      textAlign: 'center',
      backgroundColor: 'transparent',
      color: 'red',
      border: 0,
      fontSize: '12px',
    }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }